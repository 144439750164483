import axios from 'axios';
class UserService {
    getPublicContent() {
        return axios.get('all');
    }
    getUserBoard() {
        return axios.get('user');
    }
    getModeratorBoard() {
        return axios.get('mod');
    }
    getAdminBoard() {
        return axios.get('admin');
    }
    get_customers(page, data) {
        return axios.post('/users/get_customers?page=' + page, data)
    }
    get_admins(page, data) {
        return axios.post('/users/get_admins?page=' + page, data)
    }
    get_roles(page) {
        return axios.get('roles?page=' + page);
    }
}
export default new UserService();