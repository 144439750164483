<template>
  <v-container fluid class="py-6">
    <v-snackbar
      top
      v-model="snackbar.visible"
      :color="snackbar.color"
      class="snackbar-shadow"
    >
      <div class="d-flex align-start alert-notify">
        <v-icon size="24" class="text-white mr-5">ni ni-bell-55 </v-icon>
        <p class="mb-0">
          <span class="font-size-root font-weight-600">{{
            snackbar.name
          }}</span>
          <br />
          {{ error_msg }}
        </p>
      </div>

      <template v-slot:action="{ attrs }">
        <v-btn
          icon
          elevation="0"
          max-width="136"
          :ripple="false"
          height="43"
          class="font-weight-600 text-capitalize py-3 px-6 rounded-sm"
          color="rgba(255,255,255, .85)"
          @click="snackbar.visible = false"
          v-bind="attrs"
        >
          <v-icon size="13">fas fa-times</v-icon>
        </v-btn>
      </template>
    </v-snackbar>
    <v-row>
      <v-col cols="12">
        <v-card class="light">
          <v-app-bar color="light" class="border-left-primary">
            <v-toolbar-title>{{ $t("role.Roles") }}</v-toolbar-title>
            <router-link
              style="text-decoration: none"
              :to="'/roles/create'"
              class="py-5 px-6 mb-0 ms-auto"
              v-if="$store.getters['auth/str_per'].indexOf('role-create') > -1"
            >
              <v-icon class="custome-btn btn-primary">fas fa-plus</v-icon>
            </router-link>
          </v-app-bar>
          <Table
            :loading="loading"
            :headers="header"
            :items="roles"
            :paginate="paginate"
            :btn_table="btn_table"
            :btn_loading="btn_loading"
            :btns="btns"
            @action="action"
            @get_data_paginate="get_data_paginate"
            @set_option="set_option"
            ref="table"
          ></Table>
          <!-- @event_btn="event_btn" -->
          <!-- @change_pre_page="ChangePrePage" -->
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>
<script>
import Table from "../Components/PaginationTable.vue";
import UserService from "../../services/user.service";
export default {
  name: "Role-index",
  components: { Table },
  data() {
    return {
      roles: [],
      error_msg: "",
      snackbar: {
        color: "#f5365c",
        class: "danger",
        name: "Danger",
        visible: false,
      },
      filters: [],
      options: {},
      btn_table: {
        excel: false,
        print: false,
      },
      paginate: {
        page: 1,
        itemsPerPage: 0,
        total: 0,
      },
      btn_loading: {
        excel: false,
        print: false,
      },
      loading: true,
      header: [
        // { text: this.$i18n.t("ID"), value: "id", align: "center" },
        {
          text: this.$i18n.t("group.Name"),
          value: "name",
          align: "center",
        },
        {
          text: this.$i18n.t("general.Created at"),
          value: "created_at",
          align: "center",
        },
        {
          text: this.$i18n.t("general.Updatet at"),
          value: "updated_at",
          align: "center",
        },
        {
          text: this.$i18n.t("general.Action"),
          value: "btns",
          align: "center",
        },
      ],
      btns: [
        {
          type: "icon",
          text: "edit",
          color: "bg-gradient-success",
          icon: "mdi-pencil",
          permission: "role-update",
        },
        {
          type: "icon",
          text: "delete",
          color: "bg-gradient-success",
          icon: "mdi-delete",
          permission: "role-delete",
        },
      ],
    };
  },
  methods: {
    getRoles() {
      return UserService.get_roles(this.paginate.page).then(
        (response) => {
          this.loading = false;
          this.paginate.total = response.data.data.total;
          this.paginate.itemsPerPage = parseInt(response.data.data.per_page);
          this.roles = response.data.data.data;
        },
        (error) => {
          this.loading = false;
          // console.log(error);
        }
      );
    },
    set_option(val) {
      this.options = val;
    },
    get_data_paginate(page) {
      this.paginate.page = page;
      this.getRoles();
    },
    action(id, name) {
      if (name == "edit") {
        this.editRole(id);
      } else if (name == "delete") {
        this.DeleteRole(id);
      }
    },
    editRole(id) {
      this.$router.push("/roles/" + id);
    },
    DeleteRole(id) {
      this.$swal({
        title: "Are you sure you want to delete this role?",
        text: "You won't be able to revert this!",
        showCancelButton: true,
        confirmButtonText: "Delete",
        cancelButtonText: "Keep it!",
        customClass: {
          confirmButton: "btn bg-gradient-danger",
          cancelButton: "btn bg-gradient-success",
        },
        preConfirm: () => {
          axios
            .post("roles/delete/" + id)
            .then((response) => {
              this.error_msg = response.data.message;
              this.snackbar.color = "#2dce89";
              this.snackbar.class = "success";
              this.snackbar.name = "Success";
              this.snackbar.visible = true;
              this.getRoles();
            })
            .catch((error) => {
              this.loading = false;
              this.error_msg =
                (error.response && error.response.data.message) ||
                error.message ||
                error.toString();
              this.snackbar.color = "#f5365c";
              this.snackbar.class = "error";
              this.snackbar.name = "Error";
              this.snackbar.visible = true;
            });
        },
      });
    },
  },
  mounted() {
    this.getRoles();
    document.title = this.$i18n.t("role.Roles");
  },
};
</script>
